import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContexts';
import axios from '../api/axios';
import { Link } from "react-router-dom";
import MemberDirectory from '../pages/members/MemberDirectory';

const Leftside = () => {
    const { userData, setbreadcrumb } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");



    return (
        <div className="col pe-lg-4">
            <div className="card mb-4">
                <div className="card-body">
                    <div className="text-center mt-3">

                        {userData && <img src={userData.profile_pic?.file.url || process.env.REACT_APP_PUBLIC_URL + '/assets/images/no-user.jpg'} alt="user" className="avtar-xl border-radius mb-3" />}
                        <h6 className="mb-0 text-black"><strong> Welcome</strong></h6>
                        <h6 className="mb-0 text-green"><strong> {userData?.first_name} {userData?.last_name}</strong></h6>
                    </div>

                </div>
                <hr className="hr-2" />
                <div className="card-body d-flex justify-content-center px-5 px-md-5">
                    <ul className="CardUls">
                        <li>
                            <Link to="/my-account" className="text-black" onClick={() => { setbreadcrumb("My Account"); localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', "My Account"); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx="12" cy="7" r="4" /></svg>
                                My Account
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-message" className="text-black" onClick={() => { setbreadcrumb("My Messages"); localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', "My Messages"); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg>
                                My Messages
                            </Link>
                        </li>

                    </ul>

                </div>

            </div>

            <div className="card mb-4">
                <div className="card-header text-center">
                    Member Directory
                </div>
                <MemberDirectory />
            </div>

        </div>
    );
}
export default Leftside;