import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContexts";
import axios from "../../../api/axios";
import { Card, Spinner } from "react-bootstrap";

const BasicContentDetail = ({ eventId, data }) => {

    const [storyDetail, setStoryDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");

    useEffect(() => {
        if (data.machine_name == "basic_content" && data.type == "1" && data.entity_id != null) {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
            };
            axios.get(`/basic-content/${data.entity_id}`, { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    setStoryDetail(response.data);
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }
    }, [token, data, eventId]);
    return (
        <React.Fragment>
            {loading ? <div className="col-12 text-center mt-2">
                <Spinner animation="border" variant="primary" />
            </div> :
                <div className='mt-3 CardIns'>

                    <div >
                        <div className='mb-4'>
                            <div className='row  justify-content-center'>

                                <div className='col-md-4 mb-3 mb-md-0'>
                                    {storyDetail?.image_url && <Card.Img variant="top" src={storyDetail?.image_url} />}
                                </div>

                                <div className='col-md-5 ps-md-5 ancorFont17'>
                                    <h2 className='mb-3 pt-0 pb-0 card-header font-25 border-0 ps-0'>{storyDetail.title}</h2>
                                    {storyDetail.subtitle && <div className='mb-4'> <h5><strong>{storyDetail.subtitle}</strong></h5></div>}

                                    <div dangerouslySetInnerHTML={{ __html: storyDetail.content }}></div>
                                    <div>
                                        <img alt='' src={storyDetail.link_icon?.url} style={{ maxWidth: "60px", maxHeight: "40px" }}></img>
                                        {storyDetail && storyDetail.link && storyDetail.link.match('http') ? <a href={storyDetail.link} className='ms-2 btn btn-green py-2 px-4'>{storyDetail.link_text}</a> : storyDetail.link && <a href={`mailto:${storyDetail.link}`} className='ms-2  btn btn-green py-2 px-4'>{storyDetail.link_text}</a>}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>}
        </React.Fragment>
    )
}
export default BasicContentDetail;