import React, { useContext, useEffect, useState } from "react";
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { login } from '../../api/auth';
import { toast } from "react-toastify";
import { UserContext } from '../../contexts/UserContexts';
import { Link, useNavigate } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';


const loginValidation = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
    vendor_id: Yup.string().required("vendor is required")
});

export default function Login() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setUserData, userData, setIsLoggedIn } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + '_Token');

    const loginSubmit = (formData) => {
        login(formData).then((result) => {

            if (result.status == "200") {
                localStorage.setItem(process.env.REACT_APP_NAME + "_Token", result.data.api_token);
                reactLocalStorage.setObject(process.env.REACT_APP_NAME + "_userData", result.data);
                setUserData(result.data);

                setIsLoggedIn(true);
                if (result.data && result.data.has_temp_pass === false) {
                    toast.success("Logged in successfully");
                    navigate('/');
                } else {
                    toast.warning("Please reset your password to login.");
                    navigate('/reset-password');
                }
                setLoading(false);
            } else {
                toast.error(result.response.data.error);
                setLoading(false);
                localStorage.setItem(process.env.REACT_APP_NAME + "_Token", "");
            }
        }).catch((error) => {
            toast.error("Please enter correct details");
            localStorage.setItem(process.env.REACT_APP_NAME + "_Token", "");
            setLoading(false);
        })
    };
    useEffect(() => {
        if (token && userData.has_temp_pass === false) {
            navigate('/');
        }
    }, [token]);



    return (
        <div className="container h-100vh pb-4 loginpages">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-4">

                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            vendor_id: process.env.REACT_APP_VENDOR_ID
                        }}
                        validationSchema={loginValidation}
                        onSubmit={(values, actions) => {
                            loginSubmit(values);
                            actions.setSubmitting(false);
                            setLoading(true);
                        }} >
                        {({ errors, touched, isSubmitting }) => (
                            < Form >

                                <div className="form-group  card pb-5">
                                    <div className="card-body">
                                        <h3 className="text-center card-header mb-5">Member Login</h3>
                                        <div className="my-4 m-auto px-5">
                                            <label htmlFor="email">Email address</label>
                                            <Field type="email" className="form-control " id="email" placeholder="Email ID" name="email" />
                                            {errors.email && touched.email ? (<div className="text-danger mt-2">{errors.email}</div>) : null}

                                        </div>
                                        <div className="mb-4 px-5">
                                            <label htmlFor="password">Password</label>
                                            <Field type="password" className="form-control" id="password" placeholder="password" name="password" />
                                            {errors.password && touched.password ? (<div className="text-danger mt-2">{errors.password}</div>) : null}
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-black px-4" disabled={loading}>{loading ? "Loading..." : "Login"}</button>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Link to="/forgot-password" className="text-primary">Reset Password</Link>
                                        </div>
                                    </div>

                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>

            </div>
        </div >
    );
}