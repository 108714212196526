import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router";
import SubHeader from "../components/SubHeader";
import axios from '../api/axios';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContexts";
import Rightside from "../components/Right";


export default function LayoutTwo() {
    const [logo, setLogo] = useState({});
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const navigate = useNavigate();

    useEffect(() => {
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
        };
        let logoDetail = axios.get('/website-configs', { params: payload })
            // .then(handleResponse)
            .then((response) => {
                setLogo(response.data);
            }).catch(err => {
                console.log("err ", err);
            });
    }, []);

    return (
        <React.Fragment>
            <header className="navbar-light sticky-top border-bottom p-0">
                <Header logo={logo} />
            </header>


            <div className="container mt-3">
                {
                    logo.banner_url &&
                    <div className="bannerMain position-relative">
                        <img src={logo.banner_url} alt="" className="bannerImg" />
                        <div className="overlay position-absolute top-0 bottom-0 left-0 right-0 d-flex align-items-center px-5">
                            {/* <h1 className="text-white">Empower, Learn, Grow</h1> */}
                        </div>
                    </div>
                }
            </div>

            <div className="container pb-4">
                <div className="row mt-3">
                    <Outlet />
                    {/* <Rightside /> */}
                </div>
            </div>




        </React.Fragment>)
}