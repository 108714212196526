
const Config = {
    APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
    AUTH_KEY: process.env.REACT_APP_AUTH_KEY,
    AUTH_SECRET: process.env.REACT_APP_AUTH_SECRET,
    ACCOUNT_KEY: process.env.REACT_APP_ACCOUNT_KEY,
    CONFIG: {
        endpoints: {
            api: "apiqb.markedprivate.com", // set custom API endpoint
            chat: "chatqb.markedprivate.com", // set custom Chat endpoint
            chatWebSocket: 'wss://chatqb.markedprivate.com:5281',
            bosh: 'https://chatqb.markedprivate.com:5281'

        },
        chatProtocol: {
            active: 1 // set 1 to use BOSH, set 2 to use WebSockets (default)
        },
        debug: { mode: 1 },// set DEBUG mode
    }
};
export default Config;