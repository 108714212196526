import axios from './axios';
import { reactLocalStorage } from 'reactjs-localstorage';

export async function login(data) {

    try {
        const response = await axios.post(`/login`, data);
        if (response.statusText === 'OK') {
            reactLocalStorage.setObject(process.env.REACT_APP_NAME + '_userData', response.data);
            localStorage.setItem(process.env.REACT_APP_NAME + '_access_token', response.data.api_token);
        }
        return response
    } catch (error) {
        return error
    }
}