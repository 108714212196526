import React, { useContext, useEffect, useState } from 'react';
import { Link, useHref, useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContexts';

export default function SubHeader({ logo }) {
    const { breadcrumb, setbreadcrumb } = useContext(UserContext);
    const param = useParams();
    const [breadcrumbItem, setbreadcrumbItem] = useState(localStorage.getItem(process.env.REACT_APP_NAME + '_breadcrumb') || '');
    const href = useHref();
    useEffect(() => {
        if (href === "/" && breadcrumb !== "Newsfeed") {
            setbreadcrumbItem("");
        } else {
            setbreadcrumbItem(localStorage.getItem(process.env.REACT_APP_NAME + '_breadcrumb'));
        }
    }, [breadcrumb, breadcrumbItem, param.id, href]);


    return (
        <div className='mt-3'>

            {/* <div className="py-3 row justify-content-end">
                <div className="col-md-6">
                    <form className="form-input">
                        <div className="mb-0 input-group">
                            <input type="email" className="form-control" id="search" placeholder="Search" />
                            <button className="input-group-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8" /><line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div> */}
            {
                logo.banner_url &&
                <div className="bannerMain position-relative">
                    <img src={logo.banner_url} alt="" className="bannerImg" />
                    <div className="overlay position-absolute top-0 bottom-0 left-0 right-0 d-flex align-items-center px-5">
                        {/* <h1 className="text-white">Empower, Learn, Grow</h1> */}
                    </div>
                </div>
            }


            <nav aria-label="breadcrumb" className='mt-4'>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/forums/dashboard" className="" onClick={() => { localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', ''); setbreadcrumb('') }}>Dashboard</Link></li>
                    {breadcrumbItem && <li className="breadcrumb-item active" aria-current="page">{breadcrumbItem}</li>}

                </ol>
            </nav>
        </div >
    )
}