import React, { useContext, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { UserContext } from "../../contexts/UserContexts";
import { toast } from "react-toastify";


const DeleteModal = ({ popup, setPopup, messageId, QB, chatdata, setChatdata }) => {
    const handleClose = () => setPopup(false);
    const { sessionToken } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const deleteMessage = () => {
        setLoading(true);
        var params = {
            token: sessionToken,
            force: 1 // remove message from all users' histories
        };
        QB.chat.message.delete(messageId, params, function (error, result) {
            if (result) {
                { (JSON.parse(result).SuccessfullyDeleted) && toast.success("Message Deleted Successfully") }
                setLoading(false);
                setPopup(false);
                let Messages = chatdata;
                let deletedMessage = Messages.filter((item) => item._id !== messageId);
                setChatdata(deletedMessage);

            } else {
                setLoading(false);
                { (JSON.parse(error.message).errors[0]) && toast.error(JSON.parse(error.message).errors[0]) }
                setPopup(false);
            }

        });
    }
    return (
        <Modal show={popup} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="text-center"><p><strong>Are you sure you want to delete this message ...?</strong></p></Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={loading} onClick={() => deleteMessage(messageId)}>
                    {loading ? "Deleting..." : "Delete"}
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default DeleteModal;