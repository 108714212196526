import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContexts";
import axios from "../../../api/axios";
import { Card, Spinner } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

const ListDetail = ({ eventId, data, menuDetail }) => {

    const [loading, setLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [listDetail, setListDetail] = useState([]);
    const [eventsDetail, setEventsDetail] = useState({});
    const [eventsInfo, setEventsInfo] = useState({});
    const [listData, setListData] = useState({});


    useEffect(() => {
        if (data.machine_name == "lists" && data.type == "0") {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
                menu_item_id: data.id,
                published: true,
                event_id: eventId

            };
            axios.get("/list", { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    setListDetail(response.data);
                    let detailData = {};

                    response.data.forEach((element, index) => {
                        detailData[index] = element.list_items?.[0] || null;
                    });
                    setListData(detailData);
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }
        if (data.machine_name == "lists" && data.type == "1" && data.entity_id != null) {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
            };
            axios.get(`/list/${data.entity_id}`, { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    setEventsDetail(response.data);
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }

    }, [token, data, eventId]);
    useEffect(() => {
        if (eventsDetail.list_items?.length > 0) {
            let firstItem = eventsDetail.list_items[0];
            setEventsInfo(firstItem);
        }
    }, [eventsDetail.list_items]);

    const listInfo = (key, data) => {
        let listDataUpdated = { ...listData }
        listDataUpdated[key] = data;
        setListData(listDataUpdated);
    };

    return (
        <React.Fragment>
            {loading ? <div className="col-12 text-center mt-2">
                <Spinner animation="border" variant="primary" />
            </div> :
                <div className="mt-3">
                    {menuDetail.entity_id === null && listDetail.map((doc, key) =>
                        <div>
                            <div className="card-header border-0">
                                <h4>{doc.name}</h4>
                            </div>



                            <Tab.Container id="left-tabs-examplea" defaultActiveKey={doc?.list_items[0]?.id}>
                                <div className="row justify-content-center ListTabs">
                                    <div className="col-md-4 pe-md-4">
                                        {doc?.list_items.map((list) => <Nav onClick={() => listInfo(key, list)} variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey={list.id}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span className="d-flex align-items-center">
                                                            <img src={list.file?.url} alt="" />
                                                            <div className="ps-3">
                                                                {list.title_line_1 && <p>{list.title_line_1}</p>}
                                                                {list.title_line_2 && <p>{list.title_line_2}</p>}
                                                                {list.title_line_3 && <p>{list.title_line_3}</p>}
                                                            </div>
                                                        </span>
                                                        <span>
                                                            <svg version="1.1" id="fi_271228" x="0px" y="0px" viewBox="0 0 492.004 492.004">
                                                                <g>
                                                                    <g>
                                                                        <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
                                                                c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
                                                                c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
                                                                c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"></path>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>

                                        </Nav>)}
                                    </div>
                                    <div className="col-md-6 ps-md-4">
                                        <Tab.Content>
                                            {listData[key] && <Tab.Pane eventKey={listData[key].id}>
                                                <div className='col-12 me-2'>
                                                    <Card className='CardIns'>
                                                        <Card.Title className="mb-3 pt-0 pb-0 card-header border-0 ps-0">
                                                            {listData[key].title_line_1 && <h6>{listData[key].title_line_1}</h6>}
                                                            {listData[key].title_line_2 && <p>{listData[key].title_line_2}</p>}
                                                            {listData[key].title_line_3 && <p>{listData[key].title_line_3}</p>}
                                                        </Card.Title>
                                                        <div className="image-fluid align-self-center" style={{ height: "300px", width: "300px" }}>
                                                            <Card.Img className="card-img-top" style={{ maxHeight: "300px" }} variant="top" src={listData[key]?.file?.url} />
                                                        </div>
                                                        <Card.Body>
                                                            {listData[key].links && <div className="border px-4 py-3 rounded-20">
                                                                {listData[key].links.map((link) => {
                                                                    return <div className="row ">
                                                                        <p className="col-6">{link.text}</p>
                                                                        <a href={link.url} target="_blank" className="col-6 " >   <p className="text-primary" >{link.url}</p></a>
                                                                    </div>
                                                                })}
                                                            </div>}
                                                            <div dangerouslySetInnerHTML={{ __html: listData[key].description }} style={{ fontFamily: listData[key].font_family }}></div>

                                                        </Card.Body>
                                                    </Card>
                                                </div></Tab.Pane>}
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    )
                    }
                    {
                        menuDetail.entity_id !== null && <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                            <div className="row justify-content-center ListTabs">
                                <div className='col-md-4 pe-md-4'>
                                    {eventsDetail.list_items?.map((doc, index) => {
                                        return <Nav onClick={() => setEventsInfo(doc)} variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey={index}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <span className="d-flex align-items-center">
                                                            <img src={doc.file?.url} alt="" />
                                                            <div className="ps-3">
                                                                {doc.title_line_1 && <h6>{doc.title_line_1}</h6>}
                                                                {doc.title_line_2 && <p className="font-14">{doc.title_line_2}</p>}
                                                                {doc.title_line_3 && <p className="font-14">{doc.title_line_3}</p>}
                                                            </div>
                                                        </span>
                                                        <span>
                                                            <svg version="1.1" id="fi_271228" x="0px" y="0px" viewBox="0 0 492.004 492.004">
                                                                <g>
                                                                    <g>
                                                                        <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
                                                                c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
                                                                c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
                                                                c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"></path>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>

                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>

                                    })}
                                </div>
                                <div className='col-md-6 ps-md-4'>
                                    {eventsInfo && <Card className='CardIns'>

                                        {/* <Card.Img className="card-img-tops" variant="top" src={eventsInfo.file?.url} /> */}
                                        <Card.Body>
                                            <div className="border px-4 py-3 rounded-20 mb-3">
                                                {eventsInfo.links && eventsInfo.links.map((link) => {
                                                    return <div className="row">
                                                        <p className="col-3 fw-bold">{link.text} :-</p>
                                                        <a href={link.url} target="_blank" className="col-9 text-end" > <p className=" text-primary">{link.url}</p></a>
                                                    </div>
                                                })}
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: eventsInfo.description }}></div>

                                        </Card.Body>
                                    </Card>}
                                </div>
                            </div>
                        </Tab.Container>
                    }

                </div >}
        </React.Fragment >
    )
};
export default ListDetail;