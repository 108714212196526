import React, { useContext, useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { UserContext } from "../../../../contexts/UserContexts";

const WeblistDisplay = ({ doc, linkLabel }) => {
    const [websitesDetail, setWebsitesDetail] = useState({});
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    useEffect(() => {

        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
        };
        axios.get(`/menu/container/${doc}`, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                setWebsitesDetail(response.data);

            }).catch(err => {
                console.log("err ", err);
            });


    }, [doc]);
    console.log("*****website detail", websitesDetail);
    return (
        <React.Fragment>
            <div className="UlsNext">
        
            {websitesDetail && (websitesDetail.promo_links?.length > 0) && <>
                {websitesDetail.promo_links?.length > 1 && <h5 className="mt-3">{websitesDetail.label}</h5>}
                {websitesDetail.promo_links.map((item) => <li className="text-green mb-1" style={{ cursor: 'pointer' }} onClick={() => window.open(item.url, "_blank")}>{item.label}</li>)}
            </>

            }
            
            </div>
        </React.Fragment >
    )
};
export default WeblistDisplay;