import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContexts';
import axios from '../../api/axios';
import { Link } from "react-router-dom";
import { Spinner } from 'react-bootstrap';

const MemberDirectory = () => {

    const [usersList, setUsersList] = useState([]);
    const [allMembersList, setAllMembersList] = useState([]);
    const { userData, setMemberId, setbreadcrumb } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let payload = {
            api_token: token,
            vendor_id: userData?.vendor_id,
            not_paginated: "1",
            status: "0"
        };
        axios.get('/customers', { params: payload })
            // .then(handleResponse)
            .then((response) => {
                let filteredUser = response.data.filter(item => item.public === true && item.chat_credentials.qb_id !== userData.chat_credentials.qb_id);
                setAllMembersList(filteredUser);
                setLoading(false);
            }).catch(err => {
                console.log("err ", err);
            });
    }, [token, userData]);

    useEffect(() => {
        if (allMembersList && allMembersList.length) {
            setUsersList(allMembersList);
        }
    }, [allMembersList]);

    const handleMemberSearch = (data) => {
        let filteredMembers = allMembersList.filter(item => item?.full_name?.toLowerCase().match(data.toLowerCase()))
        setUsersList(filteredMembers);
    };

    return (
        <React.Fragment>
            <div className="card-body">
                {loading ?
                    <div className="col-lg-6 text-center mt-2">
                        <Spinner animation="border" variant="primary" />
                    </div> : <ul className="memberList">
                        <div className='col-12  my-2 sticky-top px-2 '>
                            <input type='search' className=' form-control ' onChange={(e) => handleMemberSearch(e.target.value)} ></input>
                        </div>
                        {usersList && usersList.map((user, index) => {
                            return <li key={index} onClick={(e) => { setMemberId(user.id); }}>
                                <div className='row align-items-center'>
                                    <div className='col-9'>
                                        <Link to={`/member-profile/${user.id}`}>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    <img src={user.profile_pic?.file.url || process.env.REACT_APP_PUBLIC_URL + '/assets/images/no-user.jpg'} alt="user" className="uderim" />
                                                </div>
                                                <div className="flex-grow-1 ms-2">
                                                    <h6 className="mb-0"><strong> {user.full_name}</strong></h6>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-3'>
                                        <Link to={`/message/${user.chat_credentials.qb_id}`} onClick={() => { setbreadcrumb("My Messages"); localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', "My Messages"); }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#d5d5d5" id="Layer_1" enableBackground="new 0 0 24 24" height="27" viewBox="0 0 24 24" width="27"><g><path d="m18 1h-12c-2.757 0-5 2.243-5 5v8c0 2.414 1.721 4.434 4 4.899v3.101c0 .369.203.708.528.882.148.079.31.118.472.118.194 0 .387-.057.555-.168l5.748-3.832h5.697c2.757 0 5-2.243 5-5v-8c0-2.757-2.243-5-5-5zm-6.555 16.168-4.445 2.963v-2.131c0-.552-.448-1-1-1-1.654 0-3-1.346-3-3v-8c0-1.654 1.346-3 3-3h12c1.654 0 3 1.346 3 3v8c0 1.654-1.346 3-3 3h-6c-.07-.001-.17.006-.284.04-.12.035-.211.087-.271.128z" /><path d="m18 7h-12c-.552 0-1 .448-1 1s.448 1 1 1h12c.552 0 1-.448 1-1s-.448-1-1-1z" /><path d="m16 11h-8c-.552 0-1 .448-1 1s.448 1 1 1h8c.552 0 1-.448 1-1s-.448-1-1-1z" /></g></svg>
                                        </Link>
                                    </div>
                                </div>
                            </li>
                        })}
                    </ul>}

            </div>
        </React.Fragment >
    )
};
export default MemberDirectory;