import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContexts";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const MyMessage = () => {
    const param = useParams();
    const { userData, connectionState, sessionToken } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [loading, setLoading] = useState(true);
    const [dialougeDetails, setDialougDetails] = useState([]);

    const QB = window.QB;

    useEffect(() => {
        if (connectionState) {
            getDialogs();
        }
    }, [sessionToken, param.id, connectionState]);

    useEffect(() => {
        QB.chat.onMessageListener = onMessage;

        function onMessage(userId, message) {
            getDialogs();
        }
    }, []);
    const getDialogs = () => {
        setLoading(true);
        let params = {
            token: sessionToken,
            created_at: {
                lt: Date.now() / 1000
            },
            sort_desc: 'created_at',
            type: {
                in: [3]
            }
        };

        QB.chat.dialog.list(params, function (error, dialogs) {
            if (error) {
                console.log("******* dialoge error", error);
            } else {
                setDialougDetails(dialogs.items);
                setLoading(false);
            }
        });
    };

    const getId = (data) => {
        const findedID = data.occupants_ids.find(item => item !== userData.chat_credentials.qb_id)
        return findedID;
    };

    return (
        <React.Fragment>
            <div className="col-lg-6">
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="card-header mb-4">
                            <h4 className="">My Messages</h4>
                        </div>

                        <ul className="memberList memberListBorder1">
                            {loading ?
                                <div className="text-center mt-2">
                                    <Spinner animation="border" variant="primary" />
                                </div> :

                                (dialougeDetails && dialougeDetails.map((detail) => {
                                    return <li> <Link to={`/message/${getId(detail)}`}><div className="my-2 row" >
                                        <p className="col mb-0 fw-bold">{detail.name}</p>
                                        {(detail.unread_messages_count > 0) && <p className="col-auto mb-0"><span className="blink">{detail.unread_messages_count}</span></p>}
                                        <p className="col-12 mb-0 text-msg">{detail.last_message}</p>
                                    </div>
                                    </Link> </li>
                                }))}
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default MyMessage;