import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router";
import SubHeader from "../components/SubHeader";
import axios from '../api/axios';
import Leftside from "../components/Left";
import Rightside from "../components/Right";
import { UserContext } from "../contexts/UserContexts";


export default function Layout() {
    const [logo, setLogo] = useState({});
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");


    useEffect(() => {
        let payload = {
            api_token: token,
            vendor_id: userData?.vendor_id,
        };
        let logoDetail = axios.get('/website-configs', { params: payload })
            // .then(handleResponse)
            .then((response) => {
                setLogo(response.data);
            }).catch(err => {
                console.log("err ", err);
            });
    }, []);


    return (
        <React.Fragment>
            <header className="navbar-light sticky-top border-bottom p-0">
                <Header logo={logo} />
            </header>
            <div className="container">
                <SubHeader logo={logo} />
            </div>


            <div className="container pb-4">
                <div className="row mt-4">
                    <Leftside />
                    <Outlet />
                    <Rightside />
                </div>
            </div>




        </React.Fragment>)
}