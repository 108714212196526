import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContexts";
import axios from "../../api/axios";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

export default function ForumsCategory() {
    const params = useParams();
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [experienceCategory, setExperienceCategory] = useState({});
    const [loading, setLoading] = useState(true);
    const { userData, setExperienceId } = useContext(UserContext);

    useEffect(() => {
        setLoading(true);
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
            not_paginated: "1",
            category_id: params.id,
            published: true

        };
        if (params.id) {
            axios.get('/user/allevents', { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    let categories = [];
                    response.data.forEach(element => {
                        categories = categories.concat(element.categories);
                    });
                    const key = 'id';
                    categories = [...new Map(categories.map(item =>
                        [item[key], item])).values()];
                    categories = categories.map(cat => {

                        cat.items = response.data.filter((item) => {
                            return item.categories.find((cat2) => cat2.id === cat.id);
                        });


                        cat.items = cat.items.map((item) => {
                            let category = item.categories.find((cat2) => cat2.id === cat.id);
                            item.order = category.event_order;
                            return item;
                        }).sort((a, b) => a.order > b.order ? 1 : -1);

                        return cat;
                    });
                    categories = categories.find(cat => cat.id == params.id);
                    setExperienceCategory(categories);
                    setLoading(false)
                }).catch(err => {
                    console.log("err ", err);
                });
        }

    }, [token, params.id]);
    return (
        (!loading) ?
            <div className="col-lg-6">
                {experienceCategory && < div className="card mb-4" >
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="mb-0">{experienceCategory.name}</h5>
                            {/* <a href="javascript:void(0);" className="btn btn-sm btn-round btn-black">
                                View All
                            </a> */}
                        </div>
                        <div className="row row-minus-1">
                            {experienceCategory && experienceCategory.items.map((items, index) => {
                                return <div className="col-6 col-md-4 col-lg-3 px-1 mb-3" key={index}>
                                    <Link to={`/forums/experience/${items.id}?catId=${params.id}`} className="cardForumupper" onClick={(e) => { setExperienceId(items.id); localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', items.name) }}>
                                        <div className="cardForum">  <img src={items.background?.thumbnail.url} alt="" className="w-100" /></div>
                                        <h6>{items.name}</h6>
                                    </Link>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                }
            </div >
            :
            <div className="col-lg-6 text-center mt-5">
                <div className="col-12">
                    <Spinner animation="border" variant="primary" />
                </div>
            </div>
    )
}