import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContexts";
import axios from "../../../api/axios";
import { Card, Spinner } from "react-bootstrap";
import WeblistDisplay from "./Modal/WeblistDisplay";

const WebLinksDetail = ({ eventId, data, menuDetail }) => {

    const [loading, setLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [websitesDetail, setWebsitesDetail] = useState({});
    const [sponserDetail, setSponserDetail] = useState([]);


    useEffect(() => {
        if (data.machine_name == "custom_web_links" && data.type == "0") {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
                menu_item_id: data.id,
                published: true,
                event_id: eventId

            };
            axios.get("/menu/container", { params: payload })
                // .then(handleResponse)
                .then((response) => {

                    setSponserDetail(response.data.sort((data, prev) => data.order - prev.order));
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }
        if (data.machine_name == "custom_web_links" && data.type == "1" && data.entity_id != null) {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
            };
            axios.get(`/menu/container/${data.entity_id}`, { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    setWebsitesDetail(response.data);
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }
    }, [token, data, eventId]);



    return (
        <React.Fragment>
            {loading ? <div className="col-12 text-center mt-2">
                <Spinner animation="border" variant="primary" />
            </div> :
                <div>
                    {menuDetail.entity_id !== null && <div className='row mt-2'>{websitesDetail.promo_links?.map((doc) =>
                        <ul className='col-12 rounded '>
                            <Card >
                                <Card.Body>
                                    <div className='row'>
                                        <div className="col-12 text-center mb-3">
                                            {doc.url ? <div><a className='btn btn-green py-2 px-5 mb-3' href={doc.url} target="_blank" >{doc.label}</a><br /></div> : <h5 className='text-center '> {doc.label && (doc.label + " :")} <br /> <span className="mt-4 btn btn-green py-3 px-4"> {doc.phone}</span></h5>}
                                        </div> </div>
                                </Card.Body>
                            </Card>
                        </ul>
                    )}
                    </div>}
                    <div className="p-4 UlsNext">{menuDetail.entity_id === null && sponserDetail.map((doc) => <WeblistDisplay doc={doc.id} linkLabel={doc.label}></WeblistDisplay>)}</div>
                </div>
            }
        </React.Fragment>
    )
}
export default WebLinksDetail;