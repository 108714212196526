import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContexts";
import axios from "../../../api/axios";
import { Card, Spinner } from "react-bootstrap";

const CountdownClockDetail = ({ eventId, data, menuDetail }) => {

    const [loading, setLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const [eventCountDown, setEventCountDown] = useState([]);
    const [countDown, setCountDown] = useState({});
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [eventStartCount, setEventStartCount] = useState({});
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    useEffect(() => {
        if (data.machine_name == "countdown_clock" && data.type == "0") {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
                menu_item_id: data.id,
                published: true,
                event_id: eventId

            };
            axios.get("/countdown-clock", { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    let filteredArraay = [];
                    let eventData = response.data.map((item) => {
                        let startDate = new Date(item.countdown_date_timestamp * 1000);
                        const endDate = Date.now();
                        const diffrenceDate = startDate - endDate;
                        let totalDays = Math.ceil(diffrenceDate / (1000 * 3600 * 24));
                        let leftDays;
                        if (totalDays < 1) {
                            leftDays = "(Event is Expired)";
                        }
                        else {
                            leftDays = "(" + totalDays + `${(totalDays > 1) ? " Days left" : " Day left"}` + ")";
                        }

                        return {
                            title: item.title,
                            date: startDate.toDateString(),
                            countDownDays: leftDays
                        }

                    })
                    filteredArraay.push(...eventData, ...filteredArraay);
                    setEventCountDown(filteredArraay);
                    setLoading(false);

                }).catch(err => {
                    console.log("err ", err);
                });
        }
        if (data.machine_name == "countdown_clock" && data.type == "1" && data.entity_id != null) {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
            };
            axios.get(`/countdown-clock/${data.entity_id}`, { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    setCountDown(response.data);
                    setEventStartCount(response.data);
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }
    }, [token, data, eventId]);


    useEffect(() => {
        const intervalId = setInterval(() => {
            let startDate = new Date(countDown.countdown_date_timestamp * 1000);
            const endDate = Date.now();
            const diffrenceDate = startDate - endDate;
            setSeconds(Math.floor((diffrenceDate / 1000) % 60));
            setMinutes(Math.floor((diffrenceDate / 1000 / 60) % 60));
            setHours(Math.floor((diffrenceDate / 1000 / 60 / 60) % 24));
            setDays(Math.floor((diffrenceDate / 1000 / 60 / 60 / 24)))
        }, 100);
        return () => clearInterval(intervalId);
    }, [countDown]);


    return (
        <React.Fragment>
            {loading ? <div className="col-12 text-center mt-2">
                <Spinner animation="border" variant="primary" />
            </div> :
                <div className="mb-5">
                    {menuDetail.entity_id !== null && eventStartCount &&
                        <div className="text-center">
                            <h2 className='mb-4 pt-4 pb-0 card-header font-25 border-0 ps-0'>{eventStartCount.title}
                            </h2>
                            <div className='CountDown'>
                                <div className="CountDownSpan">
                                    <span className=" d-block">
                                        <span className="FontTime"> {days} </span>
                                    </span>
                                    <span className=" d-block">
                                        {(days > 1) ? "Days" : "day"}
                                    </span>
                                </div>
                                <div className="CountDownSpan">
                                    <span className=" d-block">
                                        <span className="FontTime"> {hours} </span> <br /> {(hours > 1) ? " hours" : " hour"}
                                    </span>
                                </div>
                                <div className="CountDownSpan">

                                    <span className=" d-block">
                                        <span className="FontTime"> {minutes}</span> <br />{(minutes > 1) ? " minutes" : " minute"}
                                    </span>
                                </div>
                                <div className="CountDownSpan">

                                    <span className=" d-block">
                                        <span className="FontTime"> {seconds}</span> <br /> {(seconds > 1) ? " seconds" : " second"}
                                    </span>
                                </div>


                            </div>

                        </div>
                    }
                    <div className="row">
                    {menuDetail.entity_id === null && eventCountDown.map((doc) =>
                        <div className='text-center col-md-4 mb-4'>
                            <h2 className="mb-4 pt-4 pb-0 card-header font-25 border-0 ps-0">{doc.title}</h2>
                            <div className="text-center CountDown">
                                
                                <div className=' CountDownSpan px-5'>
                                <h3 className=" fw-bold  mb-2 d-block">{doc.countDownDays}</h3>
                                <span>{doc.date}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            }
        </React.Fragment>
    )
}
export default CountdownClockDetail;

