import React, { useContext, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { UserContext } from "../../contexts/UserContexts";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";


const EditMyProfile = () => {
    const { userData, setUserData, setIsRequiredFieldsEmpty } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [image, setImage] = useState(userData.profile_pic?.file.url);
    const [imageFile, setImageFile] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [profileLoading, setProfileLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [updateProfile, setUpdateProfile] = useState(1);
    const [isPublic, setIsPublic] = useState(userData.public);
    const [displayFields, setDisplayFields] = useState({});
    const [initialValues, setinitialValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        public: isPublic
    });
    const [signupSchema, setSignupSchema] = useState(Yup.object({
        first_name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('This field is required'),
        last_name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('This field is required'),
        email: Yup.string().email('Invalid email').required('This field is required'),
    }));

    const onImageChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
            setImageFile(e.target.files[0]);
        }
    };

    useEffect(() => {
        let payload = {
            api_token: token,
            vendor_id: userData?.vendor_id,
        };
        axios.get('/user/profile', { params: payload })
            .then((response) => {
                setUserData(response.data);
                setUserDetail(response.data);
            }).catch(err => {
                console.log("err ", err);
            });
    }, [updateProfile]);

    const handleSubmit = (values) => {
        setLoading(true);
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
        };
        var formData = new FormData();
        var userKeys = Object.keys(userDetail);
        var valueKeys = Object.keys(values);

        valueKeys.forEach((key) => {
            if (userKeys.includes(key)) {
                if (key === "public") {
                    formData.append("public", values[key] ? 1 : 0);
                } else {
                    formData.append(key, values[key] || '');
                }
            } else {
                formData.append(`extra[${key}]`, values[key] || '');
            }
        })

        if (imageFile) {
            formData.append("profile_pic", imageFile);
        }
        axios.post(`/user/${userDetail.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    setLoading(false);
                    toast.success("Profile updated successfully");
                    // setUserData(response.data);
                    setIsPublic(response.data.public);
                    setIsRequiredFieldsEmpty(false);
                    setUpdateProfile(updateProfile + 1);
                }
            }).catch(err => {
                console.log("err ", err);

                if (err?.response?.data?.error) {
                    let message = Object.values(err.response.data.error).reduce((next, prev) => prev.concat(next), []).join(', ');
                    toast.error(message);
                }

                setLoading(false);
            });
    };

    useEffect(() => {
        if (userDetail)
            setImage(userDetail.profile_pic?.file.url);
    }, [userDetail]);
    useEffect(() => {
        if (userDetail?.public) {
            setIsPublic(userDetail?.public);
        }
    }, [userDetail?.public]);
    useEffect(() => {
        if (!userDetail || (!token)) {
            return;
        }
        let payload = {
            api_token: token,
        };
        let vendor_id = userDetail?.vendor_id;

        axios.get(`/vendor/${vendor_id}/social-profile`, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    setDisplayFields(response.data);
                    let formschema = {
                        first_name: Yup.string()
                            .min(2, 'Too Short!')
                            .max(50, 'Too Long!')
                            .required('This field is required'),
                        last_name: Yup.string()
                            .min(2, 'Too Short!')
                            .max(50, 'Too Long!')
                            .required('This field is required'),
                        email: Yup.string().email('Invalid email').required('This field is required'),
                    };
                    for (let key of response.data.required_fields) {
                        if (key === "display_email") {
                            formschema[key] = Yup.string().email('Invalid email').required('This field is required');
                        }
                        formschema[key] = Yup.string().required('This field is required');
                    }
                    setSignupSchema(() => { return Yup.object({ ...formschema }) });

                    let userMemberInitailValue = {
                        first_name: userDetail.first_name,
                        last_name: userDetail.last_name,
                        email: userDetail.email
                    };

                    for (let key in userDetail.extra) {
                        let filtredItem = response.data?.fields_to_display?.find((item => item.key === key))
                        if (!filtredItem) {
                            continue;
                        }
                        userMemberInitailValue[key] = userDetail.extra[key];
                    }
                    for (let key in userDetail) {
                        let filtredItem = response.data?.fields_to_display?.find((item => item.key === key && item !== 'extra'))
                        if (!filtredItem) {
                            continue;
                        }
                        userMemberInitailValue[key] = userDetail[key];
                    }
                    setinitialValues((inValues) => { return { ...inValues, ...userMemberInitailValue } });
                    setProfileLoading(false);
                }
            }).catch(err => {
                console.log("err ", err);
            });
    }, [userDetail, token]);

    useEffect(() => {
        if (displayFields && displayFields?.required_fields?.length > 0) {
            for (let x of displayFields?.required_fields) {
                if (userDetail && userDetail.extra && userDetail?.extra[x] === "") {
                    setIsRequiredFieldsEmpty(true);
                } else if (userDetail && userDetail.extra === null) {
                    setIsRequiredFieldsEmpty(true);
                }
                else {
                    setIsRequiredFieldsEmpty(false);
                }
            }
        }

    }, [displayFields, userDetail, setIsRequiredFieldsEmpty]);

    const getMemberDisplayObj = (values, errors, touched, setFieldValue) => {
        let userMemberObj = {};
        displayFields?.fields_to_display?.map((item, key) => {
            let requiredField = displayFields?.required_fields.find(required => required === item.key)
            if (requiredField) {
                return userMemberObj[key] = {
                    required: true,
                    key: key,
                    value: item.key,
                    label: item.label
                }
            } else {
                return userMemberObj[key] = {
                    required: false,
                    key: key,
                    value: item.key,
                    label: item.label
                }
            }
        })
        return Object.values(userMemberObj).map((obj, index) => {
            return (
                <>
                    <div className="col-md-6">
                        <div className="col-12 mb-4">
                            <label for={obj.value} className="form-label">{obj.label} {obj.required && <span className="text-danger">*</span>}</label>
                            <Field name={obj.value} type="text" className="form-control" id={obj.value} />
                            {errors[obj.value] ? (
                                <div className="text-danger">{errors[obj.value]}</div>
                            ) : null}
                        </div>
                    </div>
                </>
            )
        })
    };

    console.log(" ***** initialValues ", signupSchema);

    if (profileLoading) {
        return <div className="col-lg-6 text-center mt-5">
            <Spinner animation="border" variant="primary" />
        </div>
    }

    return (
        <React.Fragment>
            <div className="col-lg-6">
                <div className="card">
                    <div className="card-header px-4">
                        My Account
                    </div>
                    <div className="card-body py-4 px-4 px-md-5">

                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validationSchema={signupSchema}
                            onSubmit={(values, actions) => {
                                handleSubmit(values);
                            }}
                        >
                            {({ errors, touched, values, setFieldValue }) => (
                                <Form>
                                    <div className="row mb-2">
                                        <div className="col-md-6 mb-4 ">
                                            <label for="profilePic" className="form-label">Profile Picture</label> <br />
                                            <input type="file" accept="image/*" onChange={onImageChange} className="form-control" id="profilePic" style={{ display: "none" }} />
                                            <img alt="preview image" className="avtar-xxl img-cover" src={image} onClick={() => document.getElementById('profilePic').click()} />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="col-12 mb-4">
                                                <label for="first_name" className="form-label">First Name <span className="text-danger">*</span></label>
                                                <Field name="first_name" className="form-control" id="first_name" />
                                                {errors.first_name && touched.first_name ? (
                                                    <div className="text-danger">{errors.first_name}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label for="last_name" className="form-label">Last Name <span className="text-danger">*</span></label>
                                                <Field name="last_name" className="form-control" id="last_name" />
                                                {errors.last_name && touched.last_name ? (
                                                    <div className="text-danger">{errors.last_name}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-7 mb-4">
                                            <label for="email" className="form-label">Email <span className="text-danger">*</span></label>
                                            <Field name="email" type="email" className="form-control" id="email" />
                                            {errors.email && touched.email ? (
                                                <div className="text-danger">{errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-5 mb-4 ">
                                            <label for="public" className="form-label me-2" >Hide my social profile</label>
                                            <input name="public" type="checkbox" defaultChecked={!userData.public} className="form-check-input" id="public" onClick={(e) => { setFieldValue("public", e.target.checked ? false : true) }} />
                                            {errors.public && touched.public ? (
                                                <div className="text-danger">{errors.public}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                    <div className="row">
                                        <h5 className="fw-bold mb-3">Social Links :-</h5>
                                        {getMemberDisplayObj(values, errors, touched, setFieldValue)}

                                    </div>
                                    <div className="my-5 row">
                                        <div className="col-md-6 text-start">
                                            <Link to="/change-password">
                                                <button type="submit" className="btn btn-primary ">Change password</button>
                                            </Link>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button type="submit" disabled={loading} className="btn btn-black ">{loading ? "Updating..." : "Update Profile"}</button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </div>
                </div>
            </div>

        </React.Fragment >
    );
}
export default EditMyProfile;