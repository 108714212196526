import React, { useContext, useEffect, useState, } from 'react';
import { UserContext } from '../contexts/UserContexts';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from '../api/axios';
import { toast } from "react-toastify";
import { Link, useHref, useNavigate, useParams } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

export default function Header({ logo }) {

    const { userData, setbreadcrumb, setUserData, breadcrumb, setSessionToken, setIsLoggedIn, isRequiredFieldsEmpty, setIsRequiredFieldsEmpty } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [displayFields, setDisplayFields] = useState({});
    const navigate = useNavigate();
    const href = useHref();

    const handleClick = () => {
        document.getElementById("dropdown-basic").click();
    }
    const logout = () => {
        let payload = {
            api_token: token,
            vendor_id: userData?.vendor_id,
        };
        axios.post('/logout', payload)
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                // toast.success(" Logout successfully !");
                // navigate('/login');
                toast.success(" Logout successfully !");
                setIsRequiredFieldsEmpty(false);
                localStorage.removeItem(process.env.REACT_APP_NAME + "_Token", "");
                localStorage.removeItem(process.env.REACT_APP_NAME + "_appSessionToken", "");
                localStorage.removeItem(process.env.REACT_APP_NAME + '_appSession', "");
                localStorage.removeItem(process.env.REACT_APP_NAME + '_userSession', "");
                localStorage.removeItem(process.env.REACT_APP_NAME + "_userSessionToken", "");
                localStorage.removeItem(process.env.REACT_APP_NAME + "_userData", "");
                localStorage.removeItem(process.env.REACT_APP_NAME + "_breadcrumb", "");


                window.QB.logout(function (error) {
                    window.QB.destroySession(function (error) {
                        setIsLoggedIn(false);
                        setSessionToken("");
                        setUserData(null);
                        navigate('/login');
                    });

                });

            }).catch(err => {
                console.log("err ", err);
            });


    };

    useEffect(() => {
        let payload = {
            api_token: token,
        };
        let vendor_id = userData?.vendor_id;

        axios.get(`/vendor/${vendor_id}/social-profile`, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    setDisplayFields(response.data);
                }
            }).catch(err => {
                console.log("err ", err);
            });
    }, [token]);

    useEffect(() => {
        if (displayFields && displayFields?.required_fields?.length > 0) {
            for (let x of displayFields?.required_fields) {
                if (userData && userData.extra && userData?.extra[x] === "") {
                    setIsRequiredFieldsEmpty(true);
                } else if (userData && userData.extra === null) {
                    setIsRequiredFieldsEmpty(true);
                }
                else {
                    setIsRequiredFieldsEmpty(false);
                }
            }
        }

    }, [displayFields, userData]);

    useEffect(() => {
        if (!token) {
            navigate('/login');
        };
        if (token && userData.has_temp_pass === true) {
            navigate('/reset-password');
        }

    }, [token]);


    useEffect(() => {
        if (isRequiredFieldsEmpty && href !== "/my-account") {
            navigate('/my-account', { replace: true });
        }
    }, [isRequiredFieldsEmpty, href]);

    return (

        <Navbar expand="lg">
            <Container>
                <div className="d-flex align-items-center me-auto" >
                    <Link to="/" className="navbar-brand  ms-0 px-0 px-md-3" onClick={() => { localStorage.setItem(process.env.REACT_APP_NAME + "_breadcrumb", ''); setbreadcrumb(''); }}>
                        {logo && <img src={logo?.logo_url} style={{ maxHeight: "75px" }} />}
                    </Link>
                </div>

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Link to="/" className={`nav-link px-3 ${(breadcrumb === "Newsfeed") ? "active" : ""}`} onClick={() => { localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', 'Newsfeed'); setbreadcrumb('Newsfeed'); }}> Newsfeed</Link>
                        {/* <Link to="" className=" nav-link px-3">Dynamic-List-Screen-Name</Link> */}
                        <Link to="/forums/dashboard" className={`nav-link px-3 ${(breadcrumb === null) ? "active" : ""}`} onClick={() => { localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', ''); setbreadcrumb(''); }}>Dashboard</Link>
                    </Nav>
                </Navbar.Collapse>

                <div className="navbar-nav ms-auto justify-content-end">
                    <div className="nav-item text-nowrap d-flex align-items-center">
                        <a className="nav-link  userName px-2 px-md-3" href="javascript:void(0);" onClick={() => handleClick()}  >

                            <div className="d-flex"  >
                                <div className="flex-shrink-0">
                                    {userData && <img src={userData.profile_pic?.file.url || process.env.REACT_APP_PUBLIC_URL + '/assets/images/no-user.jpg'} alt="user" className="border-radius" />}
                                </div>
                                <div className="flex-grow-1 d-none d-md-block ms-2">
                                    <h6 className="mb-0"><strong>{userData?.first_name}</strong></h6>
                                    <p className="mb-0">{userData?.last_name}</p>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" style={{ display: "none" }} id="dropdown-basic">
                                            Dropdown Button
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="" ><li className='' onClick={() => { logout() }}>Logout</li></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </a>


                        <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    </div>
                </div >

            </Container>
        </Navbar >
    )
}