import React, { useEffect, useState, useContext } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { UserContext } from '../../../../contexts/UserContexts';
import axios from '../../../../api/axios';
import { toast } from 'react-toastify';

const captionValidation = Yup.object().shape({
    caption: Yup.string().required("This field is required"),

});

const ImageModal = ({ isOpen, doc, setIsOpen, setUpdateApi, updateApi }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + '_Token');


    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false)
        }
    }, [isOpen]);
    const handleClose = () => {
        setShow(false);
        setIsOpen(false)
    };

    const submitCaption = (data) => {
        setLoading(true);
        let payload = {
            api_token: token,
        };
        let formdata = {
            vendor_id: userData?.vendor_id,
            caption: data.caption,
            event_id: doc.event_id,
            gallery_id: doc.id
        };
        axios.post("/gallery/caption", formdata, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                if (response.status == "200") {
                    toast.success(response.data.message)
                    setLoading(false);
                    setIsOpen(false);
                    setUpdateApi(updateApi + 1);
                }

            }).catch(err => {
                console.log("err ", err.response);
                setLoading(false);
            });

    };

    return (
        <React.Fragment>
            <div style={{ width: "100%" }}>
                <Modal show={show} onHide={handleClose} size='md'>
                    <Modal.Header>
                        <CloseButton data-bs-theme="secondary" className='bg-secondary p-2' onClick={() => handleClose(false)} />
                    </Modal.Header>
                    {doc.file?.type === "image" && <div className='cardForum text-center m-auto' style={{ width: "100%" }}>
                        <img style={{ width: "100%" }} alt='' className='img-cover' src={doc.file?.url} />
                    </div>}
                    {doc.file?.type === "video" && <div className="text-center m-auto" style={{ width: "100%" }}>
                        <video style={{ width: "100%" }} className='img-cover' src={doc.file?.url} controls></video>
                    </div>}
                    {(doc.file?.user_id === userData?.id) && <Formik
                        initialValues={{
                            caption: doc.caption,
                        }}
                        validationSchema={captionValidation}
                        onSubmit={(values, { resetForm }) => {
                            setLoading(true);
                            if (values.caption.trim() !== "") {
                                submitCaption(values);
                                resetForm();
                            }
                        }} >
                        {({ errors, touched }) => (
                            < Form>
                                <div className="form-group   card pb-2">
                                    <div className="card-body row">
                                        <div className="mb-3 px-5 col-8">
                                            <Field className='form-control' type='text' id='caption' name='caption' placeholder="Enter caption here"></Field>
                                            {errors.caption && touched.caption ? (<div className="text-danger mt-2">{errors.caption}</div>) : null}
                                        </div>
                                        <div className=" col-4">
                                            <button className='btn btn-primary ' disabled={loading}>{loading ? "Loading" : "Edit Caption"}</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>}
                </Modal>
            </div>

        </React.Fragment>
    );
}

export default ImageModal;