import React, { useContext, useEffect, useState } from 'react';
import axios from '../../../api/axios';
import { UserContext } from '../../../contexts/UserContexts';
import { Spinner } from 'react-bootstrap';
import ImageModal from './Modal/ImageModal';

import MediaUpload from './MediaUpload';

function nl2br(str, is_xhtml = false) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}


const MediaDetails = ({ data, eventId }) => {
    const [loading, setLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [mediaDetail, setMediaDetail] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [updateApi, setUpdateApi] = useState(1);
    const [userUpload, setUserUpload] = useState(false);


    useEffect(() => {
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
            menu_item_id: data.id,

        };
        axios.get("/gallery/user-permissions", { params: payload })
            // .then(handleResponse)
            .then((response) => {
                setUserUpload(response.data);
                console.log("*******response.data", response.data);
            }).catch(err => {
                console.log("err ", err);
            });

    }, [token, eventId, data.id]);

    useEffect(() => {
        if (data.machine_name == "media") {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
                menu_item_id: data.id

            };
            axios.get(`/event/${eventId}/gallery`, { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshe
                    setMediaDetail(response.data);
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }
    }, [data, token, eventId, updateApi]);

    return (
        <React.Fragment>
            {loading ? <div className="col-12 text-center mt-2">
                <Spinner animation="border" variant="primary" />
            </div> :
                <div>
                    {(userUpload.can_upload === 1) && < MediaUpload eventId={eventId} menu_item_id={data.id} setMediaDetail={(data) => setMediaDetail(data)} />}
                    <div className='row mt-3' >
                        {mediaDetail && mediaDetail.length === 0 && <h5 className='text-center'> No image found</h5>}
                        {mediaDetail && (mediaDetail.length > 0) && mediaDetail.map((doc) => {
                            return <div className='col-6 col-md-3 text-center mb-4' >
                                {
                                    doc.file?.type === "image" && <div className='cardForum' onClick={() => { setModalImage(doc); setIsOpen(true); }} style={{ cursor: "pointer" }}>
                                        <img alt='' className='img-cover' style={{ width: "100%", height: "100%" }} src={doc.file?.url} />
                                        {doc.caption && <p>{doc.caption}</p>}
                                    </div>
                                }
                                {
                                    doc.file?.type === "video" && <div className='cardForum video-wrapper' onClick={() => { setModalImage(doc); setIsOpen(true); }} style={{ cursor: "pointer" }}>

                                        <video style={{ width: "100%", height: "100%" }} className='img-cover video-container' src={doc.file?.url} autoPlay={false}>

                                        </video>
                                        <div className='play-button'></div>

                                    </div>
                                }
                                < p className='my-2' dangerouslySetInnerHTML={{ __html: nl2br(doc.caption) }}></p>
                            </div>
                        })}
                    </div>
                </div >}
            <ImageModal isOpen={isOpen} doc={modalImage} setIsOpen={(data) => setIsOpen(data)} updateApi={updateApi} setUpdateApi={(data) => setUpdateApi(data)} />
        </React.Fragment >
    )
};
export default MediaDetails;