import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContexts";
import axios from "../../api/axios";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

export default function Forums() {

    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [experienceCategory, setExperienceCategory] = useState([]);
    const [loading, setLoading] = useState(true);

    const { userData, setExperienceId } = useContext(UserContext);

    useEffect(() => {
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
            not_paginated: "1",
            published: true

        };
        axios.get('/user/allevents', { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                // console.log('form response', response);
                let categories = [];

                response.data.forEach(element => {
                    categories = categories.concat(element.categories);
                });
                const key = 'id';
                categories = [...new Map(categories.map(item =>
                    [item[key], item])).values()];

                // console.log('categories *** **', categories);


                categories = categories.map(cat => {

                    cat.items = response.data.filter((item) => {
                        return item.categories.find((cat2) => cat2.id === cat.id);
                    });


                    cat.items = cat.items.map((item) => {
                        let category = item.categories.find((cat2) => cat2.id === cat.id);
                        item.order = category.event_order;
                        return item;

                    }).sort((a, b) => a.order > b.order ? 1 : -1);
                    return cat;
                });
                categories.sort((a, b) => (a.order || 1000) > (b.order || 10000) ? 1 : -1);
                setExperienceCategory(categories);
                setLoading(false)
                // setExperienceCategory(response.data);
            }).catch(err => {
                console.log("err ", err);
            });
    }, [token]);

    return (
        (!loading) ?
            <div className="col-lg-6">
                {experienceCategory && experienceCategory.map((category, index) => {
                    return <div className="card mb-4" key={index} >
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h5 className="mb-0">{category.name}</h5>
                                <Link to={`/forums/category/${category.id}`}
                                    className="btn btn-sm btn-round btn-black">
                                    View All
                                </Link>
                            </div>
                            <div className="row row-minus-1">
                                {category && category.items.map((items, index) => {
                                    return <div className="col-6 col-md-4 col-lg-3 px-1 mb-3" key={index}>
                                        <Link to={`/forums/experience/${items.id}?catId=${category.id}`} className="cardForumupper" onClick={(e) => { setExperienceId(items.id); localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', items.name); localStorage.setItem(process.env.REACT_APP_NAME + '_forumCat', category.name) }}>
                                            <div className="cardForum">
                                                <img src={items.background?.thumbnail.url} alt="" className="w-100" />
                                            </div>
                                            <h6>{items.name}</h6>
                                        </Link>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                })}
            </div>
            :
            <div className="col-lg-6 text-center mt-5">
                <div className="col-12">
                    <Spinner animation="border" variant="primary" />
                </div>
            </div>
    )
}