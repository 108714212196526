import React, { useContext, useEffect, useState } from "react";
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { UserContext } from '../../contexts/UserContexts';
import { useNavigate } from "react-router-dom";
import axios from '../../api/axios';
import { toast } from "react-toastify";


const passwordValidation = Yup.object().shape({
    oldpassword: Yup.string().required("Old Password is required"),
    password: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string()
        .required('Please enter your password.')
        .oneOf([Yup.ref('password')], 'Your passwords do not match.')
});

export default function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setUserData, userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + '_Token');

    const Navigation = () => {
        navigate('/login');
    };
    const resetPassword = (data) => {
        let payload = {
            api_token: token,
            vendor_id: userData?.vendor_id,

        };
        let rawData = {
            "old_password": data.oldpassword,
            "new_password": data.password
        };
        axios.put(`/users/${userData.id}/password`, rawData, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                if (response.status == "200") {
                    toast.success(response.data.message)
                    setLoading(false);
                    setTimeout(Navigation(), 2000);
                }

            }).catch(err => {
                console.log("err ", err.response);
                if (err.response.status == "406") {
                    toast.error(err.response.data.error);
                }
                if (err.response.status == "422") {
                    toast.error(err.response.data.error.new_password[0]);
                }

                setLoading(false);
            });


    };


    return (
        <div className="container h-100vh pb-4 loginpages">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-4">

                    <Formik
                        initialValues={{
                            oldpassword: '',
                            password: '',
                            confirmPassword: '',
                            vendor_id: process.env.REACT_APP_VENDOR_ID
                        }}
                        validationSchema={passwordValidation}
                        onSubmit={(values, actions) => {
                            resetPassword(values);
                            actions.setSubmitting(false);
                            setLoading(true);
                            actions.resetForm({
                                values,
                            });
                        }} >
                        {({ errors, touched, isSubmitting }) => (
                            < Form >

                                <div className="form-group   card pb-5">
                                    <div className="card-body">
                                        <h3 className="text-center card-header mb-5">Reset Password</h3>
                                        <div className="mb-4 px-5">
                                            <label htmlFor="oldpassword">Temporary  Password</label>
                                            <Field type="password" className="form-control" id="password" placeholder="Enter temporary password" name="oldpassword" />
                                            {errors.oldpassword && touched.oldpassword ? (<div className="text-danger mt-2">{errors.oldpassword}</div>) : null}
                                        </div>
                                        <div className="mb-4 px-5">
                                            <label htmlFor="password">New Password</label>
                                            <Field type="password" className="form-control" id="password" placeholder="Enter new password" name="password" />
                                            {errors.password && touched.password ? (<div className="text-danger mt-2">{errors.password}</div>) : null}
                                        </div>
                                        <div className="mb-4 px-5">
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            <Field type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" name="confirmPassword" />
                                            {errors.confirmPassword && touched.confirmPassword ? (<div className="text-danger mt-2">{errors.confirmPassword}</div>) : null}
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-black px-4" disabled={loading}>{loading ? "Loading..." : "Reset"}</button>
                                        </div>

                                        <div className="text-center mt-4">
                                            <a href="/login" className="text-primary">Back to login</a>
                                        </div>

                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>

            </div>
        </div >
    );
}